import React from "react"
import SEO from "../components/seo"
import Footer from "../components/Shared/Footer"
import Header from "../components/Shared/Layouts/Header"

const NotFoundPage = () => {

  const metaData = {
    metaTitle: "Page Not Found",
    metaDescription: "Page Not Found"
  }

  return (
    <>
      <SEO seo={metaData} />
      <Header />
      <div className="min-h-screen flex flex-col">
        <div className="container py-12 text-center">
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NotFoundPage


